// import Mixpanel from 'mixpanel-browser';
import * as Sentry from '@sentry/react';

import { RootState, store } from '../store';
import { isBrowser } from '.';
import { context, COOKIE_KEYS } from './constants';
import { FootprintsEvents } from './footprintsEvent';
import { getCookie } from './token';

let mixpanel = null;
const FootprintsApiUrl = 'https://footprints.instamojo.com/';
interface MixpanelOptions {
  send_immediately?: boolean;
  transport?: 'xhr' | 'sendBeacon';
}

const getCommonEventProperties = () => {
  const state: RootState = store.getState();
  return {
    template: getCookie(COOKIE_KEYS.THEME_COOKIE) || state.storeInfo.storeInfo?.theme?.name || '',
  };
};

const getUserAccountId = () => {
  const state: RootState = store.getState();
  return state.storeInfo.storeInfo.accountId;
};

const FootprintsAPI = {
  /**
   * Generates browser related data to be sent to analytics service's
   * http endpoint (yet to be implemented). This data replicates
   * mixpanel's special data which is genrated by mixpanel-js library
   * internally and sent to mixpanel when we call mixpanel.track(). In
   * order to send the same data to analytics service, we need to
   * generate it manually.
   */

  sendEvent: function (eventName, eventData, callback) {
    const payload = {
      event_name: eventName,
      event_source: 'storefront-webapp',
      event_time: Math.floor(Date.now() / 1000),
      event_data: eventData,
      user_account_id: getUserAccountId(),
    };

    fetch(FootprintsApiUrl, {
      method: 'PUT',
      headers: {
        'Content-Type': 'application/json;charset=utf-8',
      },
      body: JSON.stringify(payload),
    })
      .then((response) => {
        if (!response.ok) {
          throw response;
        }
      })
      .catch((error) => {
        Sentry?.withScope((scope) => {
          scope.setTag('api', 'footprints');
          scope.setLevel('fatal');
          scope.setExtra('error', error);
          scope.setExtra('payload', payload);
          scope.setExtra('footprints_url', FootprintsApiUrl);
          Sentry.captureException(new Error('Failed Footprints Event'));
        });
      });

    if (callback instanceof Function) {
      callback();
    }
  },
};

export const trackEvent = (
  eventName: string,
  eventProperties: Record<string, unknown> | undefined,
  options?: MixpanelOptions,
  callback?: () => void,
): void => {
  if (!isBrowser()) {
    return;
  }
  try {
    const finalEventProperties = { ...eventProperties, ...getCommonEventProperties() };
    if (FootprintsEvents.includes(eventName)) {
      FootprintsAPI.sendEvent(eventName, finalEventProperties, callback);
    } else {
      if (!mixpanel) {
        import('mixpanel-browser').then((Mixpanel) => {
          mixpanel = Mixpanel;
          mixpanel?.default?.init(context.MIXPANEL_TOKEN);
          mixpanel?.default?.track(eventName, finalEventProperties, options, callback);
        });
      } else {
        mixpanel?.default?.track(eventName, finalEventProperties, options, callback);
      }
    }
  } catch (e) {
    console.error('[Failed] Analytics:', e);
  }
};
